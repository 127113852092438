// *****************
// QUERY HISTORY
// *****************
// This interface stores historical query objects for reloading and replaying
// It is a list of query objects in order (so the last is the most recent)
import shared from '../components/shared.js'

export default {
    namespaced: true,
    state: {
        history: [

        ],
    },
    mutations: {
        STORE_QUERY_OBJECT(state, query_object) {
            // When we are loading query and response data:
            state.history.push(query_object)
          },      
    },
    actions: {       
        // load the querydata for a particular query_uuid from the database
        get_query_data: async function({commit, getters}, query_uuid) {
            return this.$axiosQ.jumpQueue('gcapi', {
                    method: 'get',
                    url: '/api/query/'+query_uuid+'/',
                    headers: {responseType: 'json'},
                    params: {enrich: 'true'},
            }).then((response) => {
                this.commit('query_store/STORE_QUERY_OBJECT', response.data)
            })
        },

        // ------------
        // DATA PARSING
        // ------------

        //search module
        load_search_module: async function({}, query_uuid) {
            let query_data = this.getters['query_store/getQueryByUUID'][query_uuid] 
            // Set the result type and search module.
            try {
                this.commit('SET_SEARCHMODULE', this.state.searchmodules.find(searchmodule => 
                    searchmodule.pk === query_data.search_module
                ))
            }
            catch (TypeError) {
                console.log('Could not set Searchmodule with PK, probably, the search module is no longer attached to the organisation. Fallback to type based.')
                this.commit('SET_SEARCHMODULE_BY_RESULT_TYPE', query_data.response_collection.collection_type)
            }
        },

        // Load Searchbox and Query collection
        load_searchbox_and_query_collection: async function({}, query_uuid) {
            let query_data = this.getters['query_store/getQueryByUUID'][query_uuid] 
            // If the query has plain text, we make a searchbox record.
            if(query_data.query_text) {
                var searchbox_record = {
                    "fields": {
                        "text": query_data.query_text,
                        },
                    "source": "searchbox",
                    "identifier": "identifier~~query_text",
                    "record_type": "record"
                }
            }
            else {
                // the searchbox is split from the other query_collection
                var searchbox_record = _.find(query_data.collection.records, (record) => { 
                    return record.source == 'searchbox'
                });
                // The rest of the records form a list
                var query_records = _.filter(query_data.collection.records, (record) => {
                    return record.source !== 'searchbox'
                })
            }

            // If we still don't have a searchbox record, we pick the first collection item.
            if (!searchbox_record) {
                searchbox_record = query_data.collection.records.shift()
            }
            // Finally, we update the store:
            // Set the searchbox in the root store level:
            this.commit('SET_SEARCHBOX', {
                records: [searchbox_record],
                // We set the user_input to the display_name of the first record. 
                user_input: searchbox_record.fields.display_name??searchbox_record.fields.text
            })
            // and we set the query collection to query collection (or empty list if undefined)
            this.state.draft_query.collection = {
                records: query_records??[]
            }
        },

        // load searches (autoloads Conflict of Interest as well)
        load_search: async function({}, query_uuid) {
            let query_data = this.getters['query_store/getQueryByUUID'][query_uuid] 
            if (query_data.search) {
                console.log('Setting search to:', query_data.search)
                query_data.search.included_records = _.map(
                    query_data.search.included_records, shared.unpack_inex_record
                )
                query_data.search.excluded_records = _.map(
                    query_data.search.excluded_records, shared.unpack_inex_record
                )
                // make sure that there's a bucket set up for CoI field:
                if (!query_data.search.conflict_of_interest) {
                    query_data.search.conflict_of_interest = {'records': []}
                }
                // make sure that the authors have a record type of 'author'
                else if (query_data.search.conflict_of_interest.records.length) {
                    query_data.search.conflict_of_interest.records = query_data.search.conflict_of_interest.records.map(
                        function(record) {
                            record.record_type = "author"
                            if(!record.display_name) {
                                record.display_name = record.fields.display_name
                            }
                            return record
                        }
                    )
                }
                this.commit('SET_SEARCH', query_data.search)
                this.commit('SET_SIDEBAR', 'collection')
            }
        },

        //filter presets
        query_and_load_filter_presets: async function({}, query_uuid) {
            let _this = this
            let query_data = this.getters['query_store/getQueryByUUID'][query_uuid] 
            if (query_data.filter_presets) {
                _.forEach(query_data.filter_presets, function(filter_preset_uuid) {
                    _this.get_and_load_filter_preset_by_uuid(filter_preset_uuid)
                })
            }
        },
        get_and_load_filter_preset_by_uuid({}, filter_preset_uuid) {
            this.$axiosQ.jumpQueue('gcapi', {
                method: 'get',
                url: '/api/filter_presets/'+filter_preset_uuid+'/',
                headers: {responseType: 'json'},
            }).then((response) => {
                console.log(response)
                this.dispatch('load_filter_preset', response.data)
            })
        },
        // filters
        load_filters: async function({}, query_uuid) {
            let _this = this
            let query_data = this.getters['query_store/getQueryByUUID'][query_uuid] 
            _.each(query_data.filters, function (filter) {
                if (filter.type === "author_position") {
                  _this.commit('SET_AUTHOR_WEIGHT', filter.value)
                } 
                else {
                  _this.commit('SET_FILTER', filter)
                }
            })
        },
        //results
        load_results: async function({}, query_uuid) {
            let query_data = this.getters['query_store/getQueryByUUID'][query_uuid] 
            this.commit('SET_RESPONSE_COLLECTION', query_data.response_collection)   
            this.commit('SET_SEARCH_STARTED', 1)
            this.commit('SET_LOADING_STATE', 0)
        },
    },
    getters: {
        getQueryUUIDs(state) {
            return _.map(state.history,'uuid')
        },
        getQueryByUUID(state) {
            return _.keyBy(state.history, 'uuid')
        }
    }
  }