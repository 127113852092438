// *****************
// SHARING STORE
// *****************
// This interface facilitates the sharing of objects
// such as Searches, Queries and Filter Presets with
// different users and teams in your organisation.
export default {
    namespaced: true,
    state: {
        users: [],
        teams: []
    },
    getters: {

    },
    mutations: {
    },
    actions: {
        async get_users({state}) {
        // Function that updates the available users,
        // only called once.
            if (!state.users.length) {
                this.$axiosQ.jumpQueue('gcapi', {
                    method: 'get',
                    url: '/api/users/',
                    headers: {responseType: 'json'},
                }).then((response) => {
                    console.log('users_in_org', response)
                    state.users = response.data
                })
            }
        },
        async get_teams({state}) {
        // Function that updates the available users,
        // only called once.
            if (!state.teams.length) {
                this.$axiosQ.jumpQueue('gcapi', {
                    method: 'get',
                    url: '/api/teams/',
                    headers: {responseType: 'json'},
                }).then((response) => {
                    console.log('teams in organisation', response)
                    state.teams = response.data
                })
            }
        },
    }
}