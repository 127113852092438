// *****************
// CONFLICT OF INTEREST STORE
// *****************
// This interface stores conflict of interest information


export default {
    namespaced: true,
    state: {
      // input identifiers for CoI check
      check: [],
      against: [],
      // options for CoI check
      active_methods: ['coauthors', 'coaffiliated'],
      crossref_types: ["journal-article", "posted-content"],
      // COIs evaluated from 01-01 of the following year:
      since_year: 2018,
      only_first2_last2_authors: false,
      // display
      loading: {
        shared_affiliations: false,
        shared_publications: false,
      },
      // IDs of potential conflicts of interest:
      work_ids: [],
      institution_ids: [],
      publications: [],
    },
    actions: {       
        /**
        * Primary entrypoint to gather Conflict of Interest information
        * @param {array} check - list of authors to check (column)
        * @param {array} against - longer authors of IDs to compare against (rows)
        * @returns void
        */
       get_coi_information: async function({state}, payload) {
        console.log('get_coi_information payload', payload)  
        this.commit('coi_store/SET_COI_INPUTS', payload)
          if (state.active_methods.includes('coauthors')) {
            this.dispatch('coi_store/get_shared_publications')
          }
          if (state.active_methods.includes('coaffiliated')) {
            this.dispatch('coi_store/get_author_institutions')
          }
          this.state.show.modal = 'coi_details';
      },
      // Call to OpenAlex to get shared publications:
      get_shared_publications: async function({state, commit, getters}) {
        commit('SET_LOADING_STATE', {field: 'publications', value: 1})
        let publications = await this.$axiosQ.jumpQueue('openalex', {
          method: "get",
          url: "https://api.openalex.org/works",
          params: {
            filter: `author.id:${getters['getIds']['check'].join('|')},author.id:${getters['getIds']['against'].join('|')}`,
            sort: 'publication_date:desc',
            per_page: 200,
          }
        }).then(response => {
          if(response.data.results.length == 200) {
            alert('More than 200 publications were found with authors from both lists. Because only the first 200 results are displayed, the table could be missing conflicts.'+
            'Please use a stricter date filter, or remove some authors from the check.')
          }
          return response.data.results
        }).catch(error => {console.log('An error occurred getting the publications', error) })
        commit('SET_LOADING_STATE', {field: 'publications', value: 0})
        commit('SET_PUBLICATIONS', publications)
      },
      // Call to OpenAlex to get institution history for an author.
      get_author_institutions: async function({state, commit, getters, rootState}) {
        let _this= this
        // list of all institutions that appear in overlap:
        let authors_with_coaffiliations =  _.filter(rootState.coi_relationships, (relationship, check_author_id) => {
          return relationship.conflicts?.coaffiliated.conflict
        })
        const all_institution_ids = _.flatMap(authors_with_coaffiliations, 'conflicts.coaffiliated.affiliation_history') 
        const unique_institution_ids = _.map(_.uniqBy(all_institution_ids, 'institution_id'), 'institution_id')
        const unknown_instituion_ids = _.filter(unique_institution_ids, (inst) => {
          return !rootState.apicache[`openalex/${inst}`]
        })
        if (!unknown_instituion_ids.length) return true
        commit('SET_LOADING_STATE', {field: 'affiliations', value: 1})
        let institution_details = await this.$axiosQ.jumpQueue('openalex', {
          method: "get",
          url: "https://api.openalex.org/institutions",
          params: {
            filter: `ids.openalex:${unknown_instituion_ids.join('|')}`,
            per_page: 200,
            select: 'id,display_name'
          }
        })
        .then(response => {return response.data.results.map(institution => {
          _this.commit('ADD_TO_APICACHE', {
            id: institution.id,
            endpoint: 'openalex',
            response: institution,
          })}
        )})
        .catch(error => console.log('enocuntered Error with authors', error))
        // for every author, check if affiliations are recent
        state.institutions = _.keyBy(institution_details, 'id')
        commit('SET_LOADING_STATE', {field: 'affiliations', value: 0})
        },
    },
    getters: {
      // returns shared affiliation data in the following manner:
      // I123: {authors: {A123: {timeline: [2014, 2015, 2016]} }
      getSharedAffiliations(state, getters, rootState) {
        // utility function to turn affiliation history list into the dict.
        function addAffiliationToResultDictionary(result, author_id, affiliation) {
          // if the affiliation ended before the start of our filter, we skip.
          if (affiliation.end_year < state.since_year) return result
          // If the affiliation started before the 'Since' filter, we set it to Since.
          const from_range = Math.max(affiliation.start_year, state.since_year)
          if(!result[affiliation.institution_id]) {
            result[affiliation.institution_id] = {}
          }
          if(!result[affiliation.institution_id][author_id]) {
            result[affiliation.institution_id][author_id] = _.range(from_range, affiliation.end_year+1, 1)
          }
          else {
            result[affiliation.institution_id][author_id].push(..._.range(from_range, affiliation.end_year+1, 1))
          }
          return result
        }
        // affiliations need to be shared between check and against IDs
        let result = {}
        let check_authors_with_coaffiliations =  _.filter(rootState.coi_relationships, (relationship, check_author_id) => {
          return getters.getIds['check'].includes(check_author_id) && relationship.conflicts?.coaffiliated.conflict
        })
        check_authors_with_coaffiliations.forEach(author => {
          author.conflicts?.coaffiliated.affiliation_history?.forEach(affiliation => {
            result = addAffiliationToResultDictionary(result, author.source_id, affiliation)
          })
          // and check authors have nested historical affiliations too
          author.conflicts?.coaffiliated.evidence.forEach((against_author) => {
            if (!getters.getIds['against'].includes(against_author.source_id)) return true
            against_author.affiliation_history?.forEach(affiliation => {
              result = addAffiliationToResultDictionary(result, against_author.source_id, affiliation)
            })
          })
        })
        // if an institution only has one author, then it is not truly shared, so we remove it.
        _.forEach(result, (authors_obj, institution) => {
          if (Object.keys(authors_obj).length <2) {
            delete(result[institution])
          }
        })
        return result
      },
      // Returns all author IDs with shared affiliations.
      getCoaffiliatedIds(state, getters) {
        let shared_affs = Object.keys(getters.getSharedAffiliations).flatMap(inst =>
          Object.keys(getters.getSharedAffiliations[inst])
        )
        let author_set = new Set(shared_affs)
        if (!author_set || !author_set.size) {return new Set([])} 
        return author_set.intersection(new Set(getters.getIds['check'])).union(
          author_set.intersection(new Set(getters.getIds['against']))
        )
      },
      // Check which publications pass all the filters of the current state
      getSharedPublications(state, getters) {
        return state.publications.filter(publication => {
          let author_ids = _.map(publication.authorships, 'author.id')
          if (state.only_first2_last2_authors) {
            var author_set = new Set(author_ids.slice(0,2).concat(author_ids.slice(author_ids.length -2)))
          }
          else {
            var author_set = new Set(author_ids)
          }
          if (!author_set || !author_set.size) {return false} 
          // return only those that match with the check AND the against
          return publication.publication_year >= state.since_year &&
            author_set.intersection(new Set(getters.getIds['check'])).size &&
            author_set.intersection(new Set(getters.getIds['against'])).size
        })
      },
      // return the author ids that share publications
      getCoAuthorIds(state, getters) {
        let shared_pubs = getters.getSharedPublications
        let author_set = new Set(_.flatMap(shared_pubs, function(pub) {
          return _.map(pub.authorships, 'author.id')
        }))
        if (!author_set || !author_set.size) {return new Set([])} 
        return author_set.intersection(new Set(getters.getIds['check'])).union(
          author_set.intersection(new Set(getters.getIds['against']))
        )
      },
      getAllCoiAuthorsById(state) {
        const check = _.keyBy(state['check'], 'identifier')
        const against = _.keyBy(state['against'], 'identifier')
        return {...check, ...against}
      },
      getIds(state) {
        return {
          "check": _.map(state['check'], 'identifier'),
          "against": _.map(state['against'], 'identifier')
        }
      }
    },
    mutations: {
      SET_COI_INPUTS: function(state, payload) {
        state.check = payload['check']
        state.against = payload['against']
      },
      SET_AFFILIATIONS: function(state, affiliations) {
        state.affiliations = affiliations
      },
      SET_PUBLICATIONS: function(state, publications) {
        state.publications = publications
      },
      SET_LOADING_STATE: function(state, payload) {
        state.loading[payload.field] = payload.value
      }
    }
  }