// *****************
// SEARCHES STORE
// *****************
// Interface for handling the multitude
// of searches present in the Collections
// index page.
import shared from '../components/shared';

export default {
    namespaced: true,
    state: {
        // for pagination purposes, we keep track of number of items:
        searches_count: 0,
        loading: false, 
        searches: [],
    },
    getters: {
        getSearchByUUID: function(state) {
            return _.keyBy(state.searches, 'uuid')
        }
    },
    mutations: {
        UPDATE_SEARCH: function(state, payload) {
            console.log('committing mutation change to', payload)
            const index = state.searches.findIndex(search => search.uuid === payload.uuid)
            if (index !== -1) {
                state.searches.splice(index, 1, payload)
            }
            console.log('after mutation:', state.searches[index])
        },
    },
    actions: {
        /**
        * Endpoint to query for Searches of a user, or shared with a user.
        * @param {string} search_string - Query string for search name
        * @param {int} page_size - number of items per page
        * @param {int} page_num - page number, 1-indexed
        * @param {bool} exclude_shared - exclude items shared with user, default = false
        * @returns void
        */
        async get_searches({state}, {search_string=false, page_size=10, page_number=1, exclude_shared=false}) {
            // Querying the backend for searches
            state.loading=true
            this.$axiosQ.jumpQueue("gcapi", {
                method: "get",
                url: "/api/searches/",
                params: {
                    name: search_string,
                    page: page_number,
                    page_size: page_size,
                    no_shared: exclude_shared,
                }})
                .then((response) => {
                  console.log(response);
                  state.searches = _.map(response.data.results, function (search) {
                    search.included_records = search.included_records.map(
                        shared.unpack_inex_record
                    );
                    search.excluded_records = search.excluded_records.map(
                        shared.unpack_inex_record
                    );
                    search.editing = 0;
                    return search;
                });
                state.searches_count = response.data.count;
                state.loading = false
            });
        },
        // Update a search in the database
        async patch_search({getters, commit}, payload) {
            commit("UPDATE_SEARCH", payload)
            console.log('patching to:', JSON.parse(JSON.stringify(payload)))
            if (payload.uuid) {
              this.$axiosQ.jumpQueue('gcapi', {
                method: 'put',
                url: `/api/searches/${payload.uuid}/`,
                headers: {
                  responseType: 'json'
                },
                data: payload,
              }).then( function(response) {
                console.log('Update success')
                console.log(response)
              }).catch(response => {
                console.log(response)
                window.alert('Something went wrong with the update.' + response.data)
              })
            }
          },
          // delete a search from the Database.
          async delete_search({state}, search) {
            if (search.uuid) {
              var uri = `/api/searches/${search.uuid}/`
              this.$axiosQ.jumpQueue('gcapi', {
                method: 'delete', 
                url: uri
              }).then(response => {
                  var tooltip_object = {
                    anchor_element_id: "collection_title", 
                    message: response.data.message,
                    style: "success",
                    placement: "right"
                  }
                  this.commit('ADD_TOOLTIP', tooltip_object)
                  state.searches = state.searches.filter(
                    (store_search) => { return store_search.uuid !== search.uuid; }
                  )
                  state.searches_count = state.searches.length;
              });
            }
            else {
              return false
            }
          },
    }
}