import { createStore } from 'vuex';
import { createPopper } from '@popperjs/core';
import shared from '../components/shared'
import uFuzzy  from '@leeoniya/ufuzzy'
import query_store from './query_store.js'
import coi_store from './coi_store.js'
import graph_store from './graph_store.js'
import sharing_store from './sharing_store.js'
import searches_store from './searches_store.js'
import axios from "axios";

export default createStore({
  modules: {
    'coi_store': coi_store,
    'graph_store': graph_store,
    'query_store': query_store,
    'sharing_store': sharing_store,
    'searches_store': searches_store,
  },
  state: {
  // **********************
  // SEARCH BOX INFORMATION
  // **********************
  // contains the main query box data
    autocomplete_authors: {},
    searchbox_collection: {
      source: "searchbox",
      user_input: '',
      records: [
        {
          identifier: "identifier~~query_text",
          record_type: 'record',
          fields: {
            'display_name': '', 
          }
        }
      ]
    },

  // ***************************
  // QUERY INFORMATION & FILTERS
  // ***************************
  // contains filters and other details used in queries
    active_filter_preset: {},
    filters: {
      'source_type': {
        'field': 'source_type',
        'values': ['journal'],
        'type': 'include'
      },
      'publication_date': {
        'field': 'publication_date',
        'start': new Date('2018-01-01').getTime() / 1000,
        'end': null, 
        'type': 'range',
      }
    },
    // Store the current query
    draft_query: {
      search_module: null,
      // list of records used in search 
      collection: {
        records: [],
      }
    },
    active_query_uuid: '',
    topics: [],
    selected_searchmodule: {
      "pk": null, 
      "display_name":"", 
      "name": "",
      "allowed_api_endpoints":[],
      "filterable_fields": []
    },
  // *****************
  // RESULT COLLECTION
  // *****************
  // contains the result collection and information related to the result list.
  // The apicache contains Scholar, Orcid and OpenAlex calls.
    apicache: {},
    coi_methods: ["coauthors", "coaffiliated"],
    coi_relationships: {},
    response_collection: {},
    result_type: null,
    topics: [],
  // ******
  // SEARCH
  // ******
  // Searches are used to collect records and exclude them in the sidebar
    search: {
      uuid: false,
      name: '',
      included_records: [],
      excluded_records: [],
      conflict_of_interest: {
        collection_type: 'authors',
        records: []
      },
    },

  // *************
  // DISPLAY LAYER
  // *************
  // Section that describes what's visible in the client
    active_sort : {
      name: 'Relevance',
      field: 'fields.ranking_score',
      order: 'desc',
      default_order: 'desc',
    },
    selected_item: false,
    show: {
      emphasis: 'HomeSearchModuleSelector',
      results: 'as_list',
      sidebar: 'filters',
      sidebar_collections: [],
      dropdown: null,
      tooltip_message: 0,
      search_started: 0,
      modal:0,
      results_loading:0,
      modal_loading:0,
      coi_author_hover: false,
      graph_author_hover:0,
      graph_loading:0,
      saving_search:false,
      coi_loading:0,
    },
    popper: {},
    tooltips: [],
    modals: {
      coauthor: {
        display: false,
        text_queries: [],
        time_limit: 5,
        type: 'CoAuthorModal',
        size: 'large',
      },
      coi_details: {
        type: 'CoIDetails',
      },
      share_collection: {
        type: "ShareCollection",
        uuid: false,
      }
    },
  // ************************************
  // FRAMEWORK USER AND ACCESS MANAGEMENT
  // ************************************
  // Section that contains permission and user details
    xsrf: null,
    user_profile: {'name':null, 'organisation':null},
    searchmodules: [],
  },

  actions: {
    create_topics_modal: function({commit, getters}, query_author) {
      this.state.show.modal = 'author_topics';
      this.state.show.modal_loading = 1;
      this.$axiosQ.jumpQueue('openalex', {
        method: 'get', 
        params: {
          "filter": "author.id:"+query_author.identifier,
          "group_by": "topics.id",
          "sort": "count:desc",
          "per_page": 25,
        },
        url:'https://api.openalex.org/works'
      }).then(response => {
        this.state.modals['author_topics'] = {
          type: 'Topics',
          size: 'large',
          display: true,
          data: {
            query_author: query_author, 
            topic_data: response.data
          }
        }
        this.state.show.modal_loading = 0;
      })
    },
    create_coauthorship_modal: function({commit, getters}, query_author) {
      this.state.show.modal = 'coauthor';
      this.state.show.modal_loading = 1;
      if (query_author['pre_collapse_ids']) {
        var query_author_id = query_author.pre_collapse_ids.join('|')
      }
      else {query_author_id = query_author.identifier}
      this.$axiosQ.addToQueue('openalex', {
          method: 'get', 
          url:'https://api.openalex.org/works?filter=author.id:'+query_author_id+'&sort=publication_date:desc&per_page=200&mailto=info@globalcampus.ai'
        }, 3).then(response => {
        let authorships = _.map(response.data.results, 'authorships').flat()
        let grouped_authorships = _.groupBy(authorships,'author.id')
        let coauthors = {}
        _.forEach(grouped_authorships, function(authorships_object, author_id) {
          coauthors[author_id] = {
            works: [],
            authorships: authorships_object
          }
        });
        response.data.results.forEach(function (work) {
          work.authorships.forEach(function (authorship) {
            coauthors[authorship.author.id]['works'].push(work)
          })
        })
        _.forEach(coauthors, function(coauthor, author_id) {
          coauthors[author_id]['last_publication'] = _.maxBy(coauthor.works, 'publication_date')
        });
        delete(coauthors[query_author.id])
        this.state.modals['coauthor']['display'] = true
        this.state.modals['coauthor']['data'] = {
            query_author: query_author, 
            coauthors: coauthors
          }
        this.state.show.modal_loading = 0;
      });
    },

    // Queries an item in OpenAlex when it is opened in a UI popup:
    get_item:function({commit, getters}, payload) {
      if (payload['item_data']['record_type'] == 'work') {
        this.dispatch('get_work', payload).then(this.dispatch('make_popup', payload))
      }
      else if (payload['item_data']['record_type'] == 'author') {
        this.dispatch('get_author', payload).then(this.dispatch('make_popup', payload))
      }
      else if (payload['item_data']['record_type'] == 'project') {
        console.log('should be redirecting.')
        window.open(payload['item_data']['fields']['url'], '_blank')
      }
    },
    make_popup: function({commit, getters}, payload) {
      const anchor = payload.popup_anchor;
      const tooltip = document.querySelector('#tooltip');
      this.state.popper = createPopper(anchor, tooltip, {
          placement: 'bottom-start',
            modifiers: [
              {
              name: 'arrow',
              options: {
                  padding: 16, // 16px from the edges of the popper
              },
              },
          ],
      });
    },

    // Query OpenAlex for a specific Author to use it in a popover.
    get_author: function({commit, getters}, payload) {
      var author_id = payload['item_id']
      if(!Object.keys(this.state.apicache).includes(author_id)) {
        commit('SET_TOOLTIP_MESSAGE', 'Loading...')
        this.$axiosQ.addToQueue('openalex', {
          method: 'get', 
          url:'https://api.openalex.org/people/'+author_id+'&mailto=info@globalcampus.ai'}, 2).then(response => {
             var author = shared.make_author({'identifier': author_id, 'display_name': response.data.display_name, 'fields': response.data})
             commit('SET_TOOLTIPPED_ITEM', author)
             commit('SET_TOOLTIP_MESSAGE', false)
             commit('ADD_TO_APICACHE', {
              'endpoint': 'https://openalex.org',
              'id': author_id,
              'response ': author,
            })
         });
       }
       else {
         commit('SET_TOOLTIPPED_ITEM', getters.getRecordById[author_id])
       }
    },

    // Query OpenAlex for a specific work to use it in a popover.
    get_work: function({commit, getters}, payload) {
      var work_id = payload['item_id'] //long form ID
      if(!Object.keys(this.state.apicache).includes(work_id)) {
         commit('SET_TOOLTIP_MESSAGE', 'Loading...')
         this.$axiosQ.addToQueue('openalex', {
          method: 'get', 
          url:'https://api.openalex.org/works/'+work_id+'&mailto=info@globalcampus.ai'}, 3).then(response => {
              var work =  {
                  fields: response.data,
                  identifier: work_id,
                  primary_id_type: "openalex",
                  record_type:"work"
              }
              work['fields']['id'] = work_id;
              work['fields']['abstract'] = shared.inverted_index_to_text(work.fields.abstract_inverted_index)
              commit('ADD_TO_APICACHE', {
                'endpoint': 'https://openalex.org',
                'id': work_id,
                'response ': work,
              })
              commit('SET_TOOLTIPPED_ITEM', work)
              commit('SET_TOOLTIP_MESSAGE', false)
          });
        }
        else {
          commit('SET_TOOLTIPPED_ITEM', getters.getWorks[work_id])
        }
    },


    update_filter: function({commit, state}, {field_name, values, options=false}) {
      let filter_details = this.getters.getFilterByField(field_name)
      if(!filter_details) {
          filter_details = {
              'field': field_name,
              'type': 'include'
          }
      }
      if(options) {
          filter_details['options'] = options
      }
      filter_details['values'] = values
      commit('SET_FILTER', filter_details)
    },

    load_filter_preset: function({commit, state, getters}, preset) {
      console.log('loading preset', preset)
      commit("CLEAR_FILTERS")
      state.active_filter_preset = preset;
      var search_module = state.searchmodules.find((searchmodule) => {
        return searchmodule.pk == preset.search_module;
      });
      if (!search_module) {
        search_module = getters.getSearchModulesByType[preset.frontend_result_component]
      }
      preset.filter_data.filters.forEach((filter) =>{
        commit("SET_FILTER", filter);
      });
      commit("SET_COI_CHECK", preset.conflict_of_interest)
      commit("SET_SEARCHMODULE", search_module);
    },

    clear_active_filter_preset: function({commit, state}) {
      state.active_filter_preset.filter_data.filters.forEach((filter) => {
        commit("CLEAR_FILTER", filter);
      });
      state.active_filter_preset = {};
    },

    // Main Search function
    find_similar: function({commit, state}) {
        this.commit('SET_TOOLTIPPED_ITEM', false)
        this.commit('SET_LOADING_STATE', 1)
        this.commit('SET_SEARCH_STARTED', 1)
        if (!this.state['selected_searchmodule']['pk']) {
          console.log('no searchmodule selected, setting to default Works')
          this.commit('SET_SEARCHMODULE_BY_RESULT_TYPE', "Works")
        }
        if (!this.getters.getQueryCollection) {
          console.log('query is empty, aborting')
          this.commit('SET_LOADING_STATE', 0)
          this.commit('SET_SEARCH_STARTED', 0)
          alert('Please provide text or an author for your query.')
          return false
        }
        //combine draft query with query collection
        let query_object = _.clone(state.draft_query)
        if(this.state.search.uuid) {
          query_object['search'] = this.state.search.uuid
        }
        query_object['collection'] = this.getters.getQueryCollection
        query_object['filters'] = this.getters.getPermittedFilters
        this.commit('SET_EMPHASIS', 'SimilarResults')
        this.$axiosQ.jumpQueue('gcapi', {
          method: 'post', 
          url:'/api/similar/semantic/',
          data: query_object
        })
        .then(response => {
            this.commit('SET_RESULT_TYPE', this.state['selected_searchmodule']['frontend_result_component'])
            let query_uuid = response.data.query_uuid
            this.commit('SET_QUERY_UUID', query_uuid)  
            const newUrl = `/app/query/${query_uuid}/`;
            history.pushState({'query_uuid': query_uuid }, document.title, newUrl);
            query_object = {
              ...query_object,
              'uuid': response.data.query_uuid,
              'response_collection': response.data.collection
            }
            this.commit('query_store/STORE_QUERY_OBJECT', query_object)   
            this.commit('SET_RESPONSE_COLLECTION', response.data.collection) 
            this.commit('SET_LOADING_STATE', 0)
          }, response => {
            alert('There was a problem with the request. The list still shows the previous results.')
            this.commit('SET_LOADING_STATE', 0)
        });
    },

    // Function that dispatches a number of async calls to find an email address
    // for a specific author, with a specific set of providers
    find_email_addresses: async function({state}, payload) {
      if (payload.email_address) { return false;}  
      let providers = [
          "journal_website",
          "publication_pdf",
          "bing",
          "google",
      ];
      let _this = this;
      let email_addresses = []
      let async_calls = []
      let ids = payload.fields.pre_collapse_ids??[payload.identifier]
      _.each(providers, function (provider) {
        async_calls.push(_this.$axiosQ.jumpQueue('gcapi', {
          method: "post",
          url: "/api/find_email/",
          data: {
              providers: [provider],
              user_query: state.active_query_uuid,
              search_module: state.selected_searchmodule.pk,
              author_ids: ids,
            }
          }).then((response) => {
          if (response.data["emails"].length) {
              // The response is a list of email data objects, so we need the
              // first one if the responses list is not empty.
              email_addresses.push(response.data["emails"][0])
            }
          })
        )
      });
      return axios.all(async_calls).then(() => {
        // order all options:
        email_addresses = _.orderBy(email_addresses, "score", "desc");
        // Get the updated_record info and attach emails to it:
        let updated_record = this.state.response_collection.records.find(response_record => {
          return response_record.identifier == payload.identifier
        })
        // If the record was not in the response collection, it could be a popup author.
        if (!updated_record && state.selected_item.identifier == payload.identifier) {
            updated_record = state.selected_item
        }
        updated_record.fields.email_candidates = email_addresses 
        updated_record.fields.email_address = email_addresses?.[0]?.['email_address']??'Not Found'
        
        // if the updated_record is in the search, we update it with emails data.
        let updated_inex_record = this.state.search.included_records.find(included_record => {
          return included_record.identifier == payload.identifier}
        )
        if (updated_inex_record) {
          updated_inex_record.fields.email_candidates = email_addresses 
          updated_inex_record.fields.email_address = email_addresses?.[0]?.['email_address']??'Not Found'
          _this.dispatch('update_inex_record', {
            'updated_record': updated_inex_record,
            'record_model': 'included_record'
         })
       }
        // we also edit the response collection where the updated_record might be.
        this.commit('UPDATE_RECORD_IN_RESPONSE_COLLECTION', shared.unpack_inex_record(updated_record))
      });
    },
    set_conflict_of_interest_records: async function({state}, payload) {
      state.search.conflict_of_interest.records = payload
      if(state.search.uuid && state.search.conflict_of_interest.records.length) {
        this.$axiosQ.jumpQueue('gcapi', {
          method: 'patch',
          url: `/api/searches/${state.search.uuid}/`,
          data: {
            'conflict_of_interest': state.search.conflict_of_interest
          }
        }).then(response => {
          console.log(response)
        })
      }
      this.dispatch('check_conflicts_of_interest')
    },
    // Send a call to CoI endpoint to check conflicts of interest.
    check_conflicts_of_interest: async function({commit, state}, payload) {
      let _this = this;
      // if the function is called from many-to-many CoI CHecker, check and against
      // lists are provided in the payload.
      if (payload?.check && payload?.against) {
        var check = payload.check
        var against = payload.against
        // Search module might not be set:
        commit('SET_SEARCHMODULE_BY_RESULT_TYPE', 'Experts')
      }
      // get IDs for two list: resultset, and CoI authors that are examined.
      else {
        var check = Object.keys(this.getters.getCurrentResultListByID).filter(item => item !== 'undefined')
        // if there is a query author in the result, we include it in the check list:
        if (state.searchbox_collection.records[0].record_type == 'author') {
          check.push(state.searchbox_collection.records[0].identifier)
        }
        var against = _.map(state.search.conflict_of_interest.records, coi_author => {return coi_author.identifier})
      }
      state.coi_relationships = {}
      if (!check || !check.length || !against || !against.length) return false; 
      // reset Conflict of Interest relationships
      state.show.coi_loading = 1
      try {
            _this.$axiosQ.jumpQueue('gcapi', {
              method: 'post',
              url: '/api/coi/',
              data: {
                check: check,
                against: against,
                search_module: state['selected_searchmodule']['pk'],
                methods: state.coi_store.active_methods,
                since_year: state.coi_store.since_year
              }
            }).then(response => {
              state.coi_relationships = response.data
              state.show.coi_loading = 0;
            })
        }
      catch (error) {
        // Handle errors if needed
        console.error('Error in check_conflicts_of_interest:', error);
        state.show.coi_loading = 0; // Reset loading in case of an error
      }
      
    },

    /** Find more like an Author
     *  Author is created through shared.js make_author function.
     */
    more_like_expert: function({commit, state}, author) {
      let request_data = {
        result_type: "Experts",
        searchbox_collection: {
          source: "searchbox",
          user_input: author.fields.display_name,
          records: [author]
        },
      }
      let randomKey = Math.random().toString(26).substring(3)
      localStorage.setItem(`query_${randomKey}`, JSON.stringify(request_data));
      const newTab = window.open(`/app/?query_key=${randomKey}`, '_blank');
      if (!newTab) {
        alert('Popup blocked! Please allow popups for this site.');
      }
    },

    /**
     * Load query information from the LocalStorage
     * This is for instance done when a request is opened in a new tab.
     * @param {string} query_key  Key of the local storage item (used once)
     */
    load_query_from_localstorage({}, query_key) {
      let request_data = JSON.parse(localStorage.getItem(`query_${query_key}`))
      this.commit('SET_SEARCHMODULE_BY_RESULT_TYPE', request_data.result_type)
      this.commit('SET_SEARCHBOX', request_data.searchbox_collection)
      // also make sure the input is seeded with the name
      localStorage.removeItem(`query_${query_key}`)
      this.dispatch('find_similar')
    },
    
    
    /**
     * Replay a query based on the UUID of that query.
     * 
     * @param {String} query_uuid   UUID of a database query.
     */
    async replay_query({commit, getters}, last_query_uuid) {
      console.log('getting query info for query UUID:', last_query_uuid)
      this.commit('SET_LOADING_STATE', 1)
      this.commit('SET_SEARCH_STARTED', 1)
      this.commit('SET_EMPHASIS', 'SimilarResults')
      this.commit('SET_QUERY_UUID', last_query_uuid)  
      await this.dispatch('query_store/get_query_data', last_query_uuid)
      // Then, we set the following items:
      // - Searchbox and Query Collection
      this.dispatch('query_store/load_searchbox_and_query_collection', last_query_uuid)

      // - Search Module
      this.dispatch('query_store/load_search_module', last_query_uuid)
            
      // - Filter presets (can include CoI)
      this.dispatch('query_store/query_and_load_filter_presets', last_query_uuid)
      
      // - filters
      this.dispatch('query_store/load_filters', last_query_uuid)
      
      // - Load search but only if there's no search loaded already.
      if(!this.state.search.uuid) {
        this.dispatch('query_store/load_search', last_query_uuid)
      }
      
      // - Results
      this.dispatch('query_store/load_results', last_query_uuid)
    },

    // Add a set of records to included or excluded records in bulk
    add_bulk_records_to_search({commit, state}, {record_list, record_model}) {
      console.log('pre-mod record list', record_list)
      let inex_record_list = record_list.map(record => {
        return shared.make_inex_record(record, state.search.uuid)
      })
      console.log('index_record_list', inex_record_list)
      this.$axiosQ.jumpQueue('gcapi', {
        method: 'post',
        url: `/api/${record_model}s/bulk_create/`,
        headers: {
          responseType: 'json'
        },
        data: inex_record_list
      })
      .then((response) => {
        console.log('successfully appended records.')
      }).catch(function(failed_response) {
        //upon failure, remove from collection.
        console.log('error upon storing the record with this search')
        console.log(failed_response)
        commit('REMOVE_FROM_INEX_COLLECTION', {'record_to_remove':record, 'record_model':record_model})
      })
    },
 
    // Add a single record to included or excluded_records of a search
    add_record_to_search({commit, state}, {record, record_model}) {
      this.$axiosQ.jumpQueue('gcapi', {
        method: 'post',
        url: `/api/${record_model}s/`,
        headers: {responseType: 'json'},
        data: shared.make_inex_record(record, state.search.uuid)
      })
      .then((response) => {
        console.log('successfully appended record, this is the response:', response)
        // If adding was successful, we add the UUID to the record.
        this.commit('UPDATE_RECORD_IN_INEX_COLLECTION', {
          updated_record: response.data,
          'record_model': 'included_record'
        })
      }).catch(function(failed_response) {
        //upon failure, remove from collection.
        console.log('error upon storing the record with this search')
        console.log(failed_response)
        commit('REMOVE_FROM_INEX_COLLECTION', {'record_to_remove':record, 'record_model':record_model})
      })
    },

    // method to update a single Included or Excluded record
    // for instance to later add an enriched email datapoint.
    update_inex_record({commit, state}, {updated_record, record_model}) {
      // We commit changes, so that the state reflects updated record.
      this.commit('UPDATE_RECORD_IN_INEX_COLLECTION', {
        'updated_record': updated_record,
        'record_model': 'included_record'
      })
      if(updated_record.uuid) {
        // if the record is stored with a search (and therefore has a uuid), update it in the database.
        this.$axiosQ.jumpQueue('gcapi', {
          method: 'put',
          url: `/api/${record_model}s/${updated_record.uuid}/`,
          headers: {responseType: 'json'},
          data: shared.make_inex_record(updated_record, state.search.uuid)
        })
        .then((response) => {
          console.log('successfully appended record.')
        })
      }
    },

    delete_record_from_search({commit, state}, {record, record_model}) {
      console.log('deleting record', record)
      this.$axiosQ.jumpQueue('gcapi', {
        method: 'delete',
        url: `/api/${record_model}s/${record.uuid}/`
      })
    },

    create_search: async function({commit, state}) {
      // translate frontend Search into backend Search obj
      commit('TOGGLE_DISPLAY', {item: 'saving_search', 'on': true})
      var search_obj = {
        "search": state.search,
      }
      search_obj['search']['included_records'] = _.map(
        search_obj['search']['included_records'],
        record => shared.make_inex_record(record, state.search.uuid)
      )
      search_obj['search']['excluded_records'] =_.map(
        search_obj['search']['excluded_records'],
        record => shared.make_inex_record(record, state.search.uuid)
      )
      search_obj['search']['queries'] = this.getters['query_store/getQueryUUIDs']
      
      // set name to timestamp if the search is not named:
      if(!search_obj['search']['name'] || !search_obj['search']['name'].length) {
        const now = new Date();
        search_obj['search']['name'] = `Collection ${now.toISOString().slice(0, 16).replace('T', ' ')}`;
      }
      return this.$axiosQ.jumpQueue('gcapi', {
        method: 'post', 
        url: '/api/searches/',
        data: search_obj
      }).then(response => {
          var tooltip_object = {
            anchor_element_id: "collection_title", 
            message: 'Collection stored successfully',
            style: "success",
            placement: "right"
          }
          commit('ADD_TOOLTIP', tooltip_object)
          commit('TOGGLE_DISPLAY', {item: 'saving_search', on: false})
          commit('SET_SEARCH', response.data.search)
        }).catch(response => {
          var tooltip_object = {
            anchor_element_id: "collection_title", 
            message: 'There was an error storing the collection: ' + response.data,
            style: "danger",
            placement: "right"
          }
          commit('ADD_TOOLTIP', tooltip_object)
          commit('TOGGLE_DISPLAY', {item: 'saving_search', on: false})
      });
    },
  },
  getters: {
    getCollectionByType(state) {
        return _.groupBy(state.search.included_records, function(item) {
          return item.record_type + 's'
        })
    },
    countRecordsInSearch(state) {
      return state.search.included_records.length + state.search.excluded_records.length
    },
    getSearchModules:function(state) {
      return state.searchmodules
    },
    getsearchmodulesByPk: function(state) {
      return _.keyBy(state.searchmodules, 'pk')
    },
    getSearchModulesByType: function(state) {
      const type_to_searchmodule_dict = {}
      state.searchmodules.forEach(searchmodule => {
        type_to_searchmodule_dict[searchmodule['frontend_result_component']] = searchmodule
      })
      return type_to_searchmodule_dict
    },
    getFilterByField: (state, getters) => (field) => {
      return state.filters[field]
    },

    getExcludedFilterFields(state) {
      var excluded_filters = []
      _.each(state.filters, function(filter_item) {
        if (filter_item.type == 'exclude') excluded_filters.push(filter_item.field)
      })
      return excluded_filters
    },
    /** Return the filters that are allowed for the currently selected search module */
    getCurrentlyAvailableFilters: function(state) {
      if (!state.selected_searchmodule?.filterable_fields?.length) {
        return []
      }
      return state.selected_searchmodule.filterable_fields
    },

    /** Processes the filters that should accompany the request to the backend. */
    getPermittedFilters: function(state) {
      let filters_for_request = [];
      _.each(state.filters, function(filter_obj, field_name) {
        if(!state.selected_searchmodule.filterable_fields.includes(field_name)) {return true}
        filters_for_request.push(filter_obj);
      })
      // handle exception for excluded records in the stored collection
      // but only if there's a excluded_records filter allowed in the search module
      // Excluded records are named 'excluded_id' in the filter to avoid name collision with
      // excluded_records in the search.
      if(state.selected_searchmodule.filterable_fields.includes('excluded_records')) {
        filters_for_request.push({
          field: 'excluded_id',
          type: 'exclude',
          values: _.map(state.search.excluded_records, record => {
            return record.identifier
          })
        })
      }
      return filters_for_request;
    },

    getQueryCollection: function(state) {
      // The collection used in the query is a combination of the searchbox collection 
      // and the stored collection, filtering the items from the stored collection that
      // are selected to be included in the search..
      var record_list = _.clone(state.draft_query.collection.records);
      // if the search was started with an author, prepend that to the query collection too.
      if (state.searchbox_collection.records[0].record_type == 'author') {
        record_list.unshift(state.searchbox_collection.records[0])
      }
      // otherwise, create a record for the text item.
      else if (state.searchbox_collection.records[0].identifier == 'identifier~~query_text') {
        if(!state.searchbox_collection.user_input.length) {return false}
        let query_record = {
          identifier: 'identifier~~query_text',
          record_type: 'record',
          source: 'searchbox',
          fields: {text: state.searchbox_collection.user_input}
        };
        record_list.push(query_record);
      };
      return {
        collection_type: 'collection',
        records: record_list
      };
    },

    getQuerySuggestions: function(state) {
        var list = Object.values(state.autocomplete_authors)
        var needle = state.searchbox_collection.user_input
        if (!needle || !needle.length) return list
        let haystack = _.map(list, function(item) {
          return item.fields.display_name + " " + item.fields.hint
        })
        haystack = uFuzzy.latinize(haystack)
        let opts = {};
        let u = new uFuzzy(opts);
        let idxs = u.filter(haystack, needle);
        let info = u.info(idxs, haystack, needle);
        var items = [
            {
              'record_type': 'record',
              'identifier': 'identifier~~query_text',
              "fields": {
                'display_name': state.searchbox_collection.user_input
              }
            }
        ]
        idxs.forEach(function(index, match_number) {
            var item = list[index]
            item.fields['display_name_hl'] = item.fields.display_name
            if (item.fields.hint) {
                item.fields.hint_hl = item.fields.hint
            }
            var name_length = item.fields.display_name.length
            var ranges = info['ranges'][match_number]
            // loop backwards, because we're going to insert stuff in the string so the offsets will update
            for (let i = ranges.length; i > 0; i=i-2) {
                var range = {
                        start: info['ranges'][match_number][i-2],
                        end: info['ranges'][match_number][i-1]
                    }
                if (range.end <= name_length) {
                    // highlight part of name
                    item.fields.display_name_hl = item.fields.display_name_hl.slice(0,range.start) 
                                + '<strong>' + item.fields.display_name_hl.slice(range.start, range.end) + '</strong>' 
                                        + item.fields.display_name_hl.slice(range.end)
                }
                else {
                    // match found in institution name
                    range.start = range.start - name_length - 1
                    range.end = range.end - name_length - 1
                    if (!item.fields.hint_hl) {return true}
                    item.fields.hint_hl = item.fields.hint_hl.slice(0,range.start) 
                                + '<strong>' + item.fields.hint_hl.slice(range.start, range.end) + '</strong>' 
                                        + item.fields.hint_hl.slice(range.end)
                }
            }
            item.record_type = 'author'
            items.push(item)
        })

        // after fuzzy search, check if any of the IDs have a full match:
        list.forEach(item => {
          if(item.fields.external_id == needle | item.fields.id == needle) {
            item.record_type = 'author'
            item.fields['display_name_hl'] = item.fields.display_name
            items.push(item)
          }
        })
        return items
    },

    // function that returns the records of authors that have 
    // a conflict of interest, determined by the response of the 
    // async check_conflicts_of_interest().
    getAuthorsWithCoI: function(state, getters) {
      let ids_with_conflict = []
      _.map(state.coi_relationships, (coi_rel_for_expert, identifier) => {
        // if result is not in the current result list, skip.
        if (!getters.getCurrentResultListByID[identifier]) return true
        // if the expert from the result is in the CoI query list, return intersect
        if (coi_rel_for_expert.intersection) {
          ids_with_conflict.push(identifier)
          return true
        }
        // then also check all the methods for conflict:
        state.coi_methods.forEach(method => {
          if (coi_rel_for_expert?.conflicts[method]?.conflict) {
            ids_with_conflict.push(identifier)
          }
        })
      })
      // remove duplicates
      return [... new Set(ids_with_conflict)]
    },

    // Primary function that selects the currently active results
    getCurrentResultList: function (state) {
      // Handle situations where result is null
      if (!state.active_query_uuid || typeof(state.result_type) == 'undefined' || state.result_type === null) {
        return false
      }
      // filter items if there's a filtered list:
      let wo_excluded_records = _.filter(state.response_collection.records, (record, key) => {
        return !_.map(state.search.excluded_records, 'identifier').includes(record.identifier)
      }) 
      // return sorted by active order field
      return _.orderBy(wo_excluded_records, state.active_sort.field, state.active_sort.order)
    },

    getCurrentResultListByID: function(state, getters) {
      return _.keyBy(getters.getCurrentResultList, 'identifier')    
    },
    getCurrentResultStats: function(state) {
      if (!state.active_query_uuid || typeof(state.result_type) == 'undefined' || state.result_type === null) {
        return false
      }
      return state.response_collection['stats']
    },
    getActiveTopics(state) {
      return state.topics.filter((topic) => {return topic.active})
    },
    getActiveTopicIds: function(state) {
      return _.map(state.topics.filter((topic) => {return topic.active}), 'id')
    },
    getAuthorCountsByTopic(state, getters) {
      const topic_to_author = {}
      _.map(getters.getCurrentResultList, function(record) {
          if (!record.fields?.topics?.length) { return true }
          record.fields.topics.forEach(function(topic) {
              topic.id = topic.id.replace('https://openalex.org/', '')
              if (!topic_to_author[topic.id]) {
                  topic_to_author[topic.id] = {}
              }
              topic_to_author[topic.id][record.identifier] = topic.count
          })
      })
      return topic_to_author
    },
    getAuthorIDsByTopic: function(state, getters) {
      const topic_to_author = {}
      _.map(getters.getCurrentResultList, function(record) {
          if (!record.fields?.topics?.length) { return true }
          record.fields.topics.forEach(function(topic) {
            topic.id = topic.id.replace('https://openalex.org/', '')
            if (!topic_to_author[topic.id]) {topic_to_author[topic.id] = [record.identifier]}
            else {topic_to_author[topic.id].push(record.identifier)}
          })
      })
      return topic_to_author
    },
    getActiveModal: function(state) {
      return state.modals[state.show.modal]
    },
    getResultVisible: function(state) {
      return !state.results_loading && state.show.search_started
    },
    getRecordById: function(state) {
      return _.keyBy(state.response_collection.records, fields.id)
    },
  },
  mutations: {
    ADD_TO_APICACHE: function(state, payload) {
      // Cache for external API calls
      state.apicache[`${payload.endpoint}/${payload.id}`] = payload.response
    },
    SET_SIDEBAR: function(state, sidebar_option) {
      state.show.sidebar = sidebar_option;
    },
    SET_AUTHOR_WEIGHT: function(state, author_weight) {
      if (author_weight == 'any') {
        delete(state.filters['author_position'])
      }
      state.filters['author_position'] = {
          'field': 'author_position',
          'type': 'author_position', 
          'value': author_weight
        }
    },
    SET_CO_AUTHORS: function(state, co_authors) {
      state.co_authors = co_authors;
    },
    SET_COI_CHECK: function(state, payload) {
      // check if old format (OpenAlex suggester)
      console.log('coi_payload', payload)
      let collection = payload
      if(!payload || payload.length) {
        state.search.conflict_of_interest.records = []
        return
      }
      if(payload && payload.check) {
        collection = {
          "records": _.map(payload.check, shared.make_author_from_oa_suggestion),
          "collection_type": "authors",
        }
      }
      // Otherwise, it is a proper collection. We just need to ensure that record type is set:
      collection.records = collection.records.map(record => {
        record.record_type = "author"
        return record
      })
      console.log('the coi check is set to this:', collection)
      state.search.conflict_of_interest = collection
    },
    SET_CO_AUTH_FIELD: function(state, author_items) {
      state.filters['coauthors'] = {
          'field': 'coauthors',
          'type': 'coauthors', 
          'options': author_items,
          'values': author_items.map('id')
        }
    },
    SET_SEARCHMODULE: function (state, searchmodule) {
      console.log('setting search module to ', searchmodule)
      state.selected_searchmodule = searchmodule
      state.draft_query.search_module = searchmodule.pk
      state.result_type = searchmodule['frontend_result_component']
    },
    SET_SEARCHMODULE_BY_RESULT_TYPE: function(state, result_type) {
      console.log('setting searchmodule to ' + result_type)
      state.selected_searchmodule = this.state.searchmodules.find(item => {
        return item['frontend_result_component'].toLowerCase() == result_type.toLowerCase()}
      )
      state.draft_query.search_module = state.selected_searchmodule.pk
      state.result_type = state.selected_searchmodule['frontend_result_component']
    },
    SET_SEARCHMODULES: function (state, searchmodules) {
      state.searchmodules = searchmodules
    },
    SET_RESULT_TYPE: function(state, result_type) {
      state.result_type = result_type
    },
    STORE_WORK: function(state, Work) {
      state.Works.push(Work)
    },
    SET_TOOLTIP_MESSAGE: function(state, message) {
      state.show.tooltip_message = message;
    },
    SET_TOOLTIPPED_ITEM: function(state, item) {
      state.selected_item = item;
    },
    APPEND_RECORDS: function (state, additional_results){
      let adding_results = _.keyBy(additional_results, 'fields.id')
      // this modifies the query only in the frontend, causing potential problems for reloading.
      state.response_collection['records'] = {
        ...state.response_collection['records'], 
        ...adding_results
      }
    },

    // Toggle the active state of a topic in the state
    TOGGLE_ACTIVE_TOPIC: function(state, toggled_topic) {
      const topic_in_state = state.topics.find((topic) => {return topic.id == toggled_topic.id})
      topic_in_state.active = 1 - topic_in_state.active
    },
    /**
     * Set a filter.
     * 
     * state.filters is a dictionary {filter['field']: filter}.
     * 
     * @param {*} state 
     * @param {*} payload   Filter object. Is required to have a 'field' entry. 
     */
    SET_FILTER: function(state, payload) {
      if (payload.type === "author_position") {
        this.commit('SET_AUTHOR_WEIGHT', payload.value)
      }
      // check if value filter is set to empty
      if (payload.values && payload.values.length == 0) {
        delete(state.filters[payload['field']])
      }
      // check if range filter is set to empty
      if (payload.type && payload.type == 'range' && payload.start === null && payload.end === null ) {
        delete(state.filters[payload['field']])
      }
      else {
        state.filters[payload['field']] = payload
      }
    },
    SET_LOADING_STATE: function(state, new_loading_state) {
      state.show.results_loading = new_loading_state;
    },
    TOGGLE_FILTER_TYPE: function(state, payload) {
      let current_filter = state.filters[payload['field']]
      if (current_filter) {
          if(current_filter['type'] === 'exclude') {
            current_filter['type'] = 'include'
          }
          else { current_filter['type'] ='exclude' }                   
      }
      else current_filter = {
          'field': payload['field'],
          'type': 'exclude'
      }
      state.filters[payload['field']] = current_filter
    },
    TOGGLE_LANGUAGE_NL: function(state) {
      let current_filter = state.filters['language'];
      if (typeof(current_filter) === 'undefined') {
          state.filters['language'] = {type: 'language', languages: ['nl'], countries: ['NL', 'BE']}
        }
      else {
        delete(state.filters['language'])
      } 
    },
    /**
     * Set the current query
     * @param {Object} payload  Object of the form 
     * {
     *    fields: {
     *      'display_name': author.display_name or query_text,
     *    }
     *    'identifier': author.id or 'query_text',
     *    'type': 'author' or 'text'
     * }
     */
     SET_SEARCHBOX(state, payload) {
      // set the source to searchbox
      if(!payload.source) {
        payload.source = "searchbox"
      }
      // If there's an author loaded, it should also be loaded as an autocomplete suggestion.
      if (payload.records[0].record_type == 'author') {
        state.autocomplete_authors[payload.records[0].identifier] = payload.records[0]
      }
      // otherwise, we need to ensure the identifier is 'identifier~~query_text'. 
      else {payload.records[0].identifier = 'identifier~~query_text'}
      state.searchbox_collection = payload
    },
    SET_QUERY_UUID(state, uuid) {
      state.active_query_uuid = uuid;
    },
    TOGGLE_DISPLAY: function (state, {item, on}) {
      state.show[item] = on
    },
    SET_SEARCH_STARTED: function(state, payload) {
      state.show.search_started = payload
    },
    SET_EMPHASIS: function(state, new_emphasis) {
      state.show.emphasis = new_emphasis
    },
    SET_COAUTH_MODAL: function (state, payload) {
      state.modals.coauthor[payload['field']] = payload['value'];
    },
    CLOSE_MODAL: function(state) {
      state.show.modal = 0
    },
    ADD_TOOLTIP: function(state, tooltip) {
      state.tooltips.push(tooltip)
    },
    REMOVE_TOOLTIP: function(state, tooltip_index) {
      state.tooltips.splice(tooltip_index, 1)
    },
    /**
     * Enrich the first record of the searchbox collection
     * @param {Object} payload  OpenAlex payload response. 
     */
    ENRICH_SEARCHBOX_RECORD(state, payload) {
      state.searchbox_collection.records[0].fields = payload
    },
    CLEAR_FILTERS(state) {
      state.filters = {};
    },
    CLEAR_FILTER(state, filter) {
      delete(state.filters[filter['field']])
    },

    // ********************
    // COLLECTION MUTATIONS
    // ********************

    // Include or exclude a record from a collection: 
    ADD_TO_INEX_COLLECTION(state, {record_to_add, record_model}) {
      // we add the originating query link to the data item:
      let record = {
        ...record_to_add,
        "query": state.active_query_uuid,
      }
      state.search[`${record_model}s`].push(record)
      // We only update the backend search if the search is already saved.
      // In other cases, the records are in the search creation.
      if (state.search.uuid) {
        this.dispatch('add_record_to_search', {
          record: record,
          record_model: record_model
        })
      }
      // if we're including records, the sidebar should be folded open:
      if (record_model == 'included_record') {
        this.state.show['sidebar_collections'][record.record_type+'s'] = 1
      }
      // if the record is Included and its an Author we want to find an email address 
      if (record.record_type == 'author' && record_model == 'included_record') {
        this.dispatch('find_email_addresses', record)
      }
    },
    // This removes an item from the collection both in the frontend,
    // and shoots an Ajax API call to update the Search database, if a search is saved.
    REMOVE_FROM_INEX_COLLECTION(state, {record_to_remove, record_model}) {
      let updated_inex_record_list = []
      let inex_record_to_remove = {}
      console.log(`removing from collection ${record_model}:`, record_to_remove)
      state.search[`${record_model}s`].map(function(inex_record) {
        if(inex_record.identifier == record_to_remove.identifier) {
          inex_record_to_remove = inex_record
        }
        else {
          updated_inex_record_list.push(inex_record)
        }
        state.search[`${record_model}s`] = updated_inex_record_list
      })
      if (state.search.uuid && inex_record_to_remove.uuid) {
        this.dispatch('delete_record_from_search', {
          record: inex_record_to_remove,
          record_model: record_model
        })
      }
    },
    // Update one record in the response collection, e.g. after enriching with emails.
    UPDATE_RECORD_IN_INEX_COLLECTION(state, {updated_record, record_model}) {
      state.search[record_model+'s'] = state.search[record_model+'s'].map(record => {
        if (record.identifier == updated_record.identifier) {
          updated_record = shared.unpack_inex_record(updated_record)
          record = {...record, ...updated_record} // we merge it because the inex record might contain additional info
          console.log('updated inex record to', record)
        }
        return record
      })
    },

    SET_SEARCH(state, search) {
      search.excluded_records = search.excluded_records.map(shared.unpack_inex_record)
      search.included_records = search.included_records.map(shared.unpack_inex_record)
      state.search = search;
    },

    SET_RESPONSE_COLLECTION(state, response_collection) {
      state.response_collection = response_collection
      if(response_collection.stats?.topics__count_stats?.counter) {
        this.commit('SET_TOPICS', response_collection.stats.topics__count_stats.counter)
      }
    },
    // Gets the statistics for topics to load topic overview
    SET_TOPICS(state, topic_scores) {
      console.log('topic_scores', topic_scores)
      console.log(shared.topics)
      console.log(shared.subfields)
      const topic_info = _.map(topic_scores, function(value, topic_id) {
        const topic = shared.topics[topic_id]
        const subfield = shared.subfields[topic['sf.id']]
        const field = shared.fields[subfield['f.id']]
        const domain = shared.domains[field['d.id']]
        return {
          id: topic_id,
          display_name: shared.topics[topic_id]['dn'],
          domain: domain,
          score: value,
          // display states:
          hovering: false,
          active: false,
        }
      })
      state.topics = topic_info
    },
    // Update one record in the response collection, e.g. after enriching with emails.
    UPDATE_RECORD_IN_RESPONSE_COLLECTION(state, update_record) {
      state.response_collection.records = state.response_collection.records.map(record => {
        if (record.identifier == update_record.identifier) {
          record = update_record
        }
        return record
      })
    },

    SET_USER(state, user_profile) {
      state.user_profile = user_profile 
    },
    SORT_RESULT_LIST(state, sortable) {
      // check if merely flipping active filter
      if (state.active_sort.name == sortable.name) {
        state.active_sort.order = state.active_sort.order=='asc'?'desc':'asc';
      }
      else {
        state.active_sort = sortable;
        state.active_sort.order = sortable.default_order;
      }
    },
    SET_DROPDOWN(state, payload) {
      state.show.dropdown = payload
    } 
  }
})