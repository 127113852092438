// plugins/vuetify.js
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { aliases, fa } from 'vuetify/iconsets/fa'

const GlobalCampusTheme = {
    dark: false,
    colors: {
      background: '#f7f7f7',
      surface: '#fff',
      'surface-bright': '#fff',
      'surface-light': '#EEEEEE',
      'surface-variant': '#424242',
      'on-surface-variant': '#EEEEEE',
      primary: '#49BDB5',
      'primary-darken-1': '#1F5592',
      'primary-lighter-1': '#bae6e3',
      secondary: '#075F63',
      'secondary-darken-1': '#018786',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#FB8C00',
      error: '#dc3545',
      'greytext': '#9e9e9e',
      'grey_divider': '#dee2e6',
    },
    variables: {
      'rounded-lg': '10px', 
      'border-color': '#dee2e6',
      'border-opacity': 0.12,
      'high-emphasis-opacity': 0.87,
      'medium-emphasis-opacity': 0.60,
      'disabled-opacity': 0.38,
      'idle-opacity': 0.04,
      'hover-opacity': 0.04,
      'focus-opacity': 0.12,
      'selected-opacity': 0.08,
      'activated-opacity': 0.12,
      'pressed-opacity': 0.12,
      'dragged-opacity': 0.08,
      'theme-kbd': '#212529',
      'theme-on-kbd': '#FFFFFF',
      'theme-code': '#F5F5F5',
      'theme-on-code': '#000000',
    }
  }

export default createVuetify({
    theme: {
        defaultTheme: 'GlobalCampusTheme',
        themes: {
            GlobalCampusTheme,
        },
    },
    icons: {
        defaultSet: 'fa',
        aliases,
        sets: {
          fa,
        },
      },
})