import {createApp} from 'vue/dist/vue.esm-bundler.js'
import App from './App.vue'
import router from '../../router'
import 'bootstrap'; import 'bootstrap/dist/css/bootstrap.min.css';
import store from '../../store/store.js' // short for @/store/index
import '../../assets/css/shared.css'
import axios from 'axios'
import shared from '../../components/shared.js';
import 'core-js/stable/set'; // Polyfill for set methpds
import vuetify from '../../plugins/vuetify.js'; // Ensure correct path
import Multiselect from '@vueform/multiselect'; import "@vueform/multiselect/themes/default.css"
import FilterSlider from '../../components/Sidebar/Filters/FilterSlider.vue'


const app = createApp(App)
              .use(vuetify)
              .use(router)
              .component('Multiselect', Multiselect)
              .component('FilterSlider', FilterSlider)


/**
 * The implementation for Frontend Requests
 *
 * @class AxiosQueue
 * @description
 * This queue allows for spoon-feeding a set of requests 
 * where elements are added at the back and removed from the front.
 * The queue is made available through the store and global props.
 * The concurrency of the queue is set to 8.
 *
 * @addToQueue
 * this.$axiosQ.addToQueue(instanceKey, requestConfig, priorty) where
 *    - instanceKey: str, key of an Axios config that is 
 *    - requestConfig: dict, the Axios request options dictionary. 
 *    - priority: int, higher int is higher priority.
 * 
 * @QueueJumping
 * Requests that need to be direct, can jump the queue 
 * using the same signature as addToQueue:  
 * this.$axiosQ.jumpQueue(instanceKey, requestConfig, priorty)
 *
 */

class AxiosQueue {

  constructor() {
      this.queue = [];
      this.active_requests = []
      this.isProcessing = false;
      // based on max number of connections in Chrome (10), leaving 2 for queue jumpers and other requests:
      this.concurrency = 8;
      this.axiosInstances = {
        'gcapi': axios.create({
            headers: {
                "Content-Type": 'application/json;charset=utf-8',
                "X-Csrftoken": shared.get_cookie('csrftoken'),
            }
        }),
        'gcallauth': axios.create({
          headers: {
            'Content-Type': 'application/json',
            "X-Csrftoken": shared.get_cookie('csrftoken'),
          },
          baseURL: '/api/allauth/browser/v1/',
        }),
        'orcid': axios.create({}),
        'openalex': axios.create({
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
            }
        }),
      }
      // We need to add an interceptor to handle the particular gcallauth response
      this.axiosInstances['gcallauth'].interceptors.response.use(
        (response) => {
          // If the response has our API format (with status and data properties),
          // return just the inner data part
          if (response.data && 
              response.data.hasOwnProperty('status') && 
              response.data.hasOwnProperty('data')) {
            return {
              ...response,
              data: response.data.data // Unwrap the nested data
            };
          }
        }
      )
    }
    
  /**
  * Send a request that jumps the queue.
  * @param {string} instanceKey - The key identifying the Axios instance to use for the request.
  * @param {Object} requestConfig - The Axios request configuration object.
  * @returns {Promise} A Promise that resolves with the response data from the server.
  */
    jumpQueue(instanceKey, requestConfig) {
      return this.axiosInstances[instanceKey].request(requestConfig)
    } 

    
  /**
  * Adds a request to the queue, initiates queue processing if not active.
  * @param {string} instanceKey - The key identifying the Axios instance to use for the request.
  * @param {Object} requestConfig - The Axios request configuration object.
  * @param {number} [priority=0] - The priority of the request in the queue (default is 0).
  * @returns {Promise} A Promise that resolves with the response data from the server.
  */
    async addToQueue(instanceKey, requestConfig, priority = 0) {
      return new Promise((resolve, reject) => {
        this.queue.push({instanceKey, requestConfig, priority, resolve, reject });
        this.queue.sort((a, b) => b.priority - a.priority); // Sort by priority
        this.activateQueue();
      });
    }
  
    /**
     * Function that starts working the queue by moving items from queue to active_requests
     * While the queue still has items.
     */
    async activateQueue() {
      if (!this.isProcessing) {
        this.isProcessing = true;
        while (this.active_requests.length < this.concurrency && this.queue.length > 0) {
          const nextRequest = this.queue.shift();
          this.active_requests.push(nextRequest);
          this.processRequest(nextRequest);
        }
        this.isProcessing = false;
      }
    }
  
    /**
     * Function that actually processes a request, takes the parameters that Axios
     * requires for an async request
     * @param {string} instanceKey 
     * @param {object} request_object  
     * @param {function} resolve function
     * @param {function} reject function
     */
    async processRequest({ instanceKey, requestConfig, resolve, reject }) {
      const axiosInstance = this.axiosInstances[instanceKey];
      try {
        const response = await axiosInstance.request(requestConfig);
        resolve(response);
      } catch (error) {
        reject(error);
      } finally {
        this.removeFromActiveRequests(instanceKey, requestConfig);
        this.activateQueue();
      }
    }
  
    /** This function removes a completed request from the list of active requests
     * @param {string} instanceKey axios instance key (e.g. gc-api)
     * @param {object} requestConfig axios request configuration
     */
    removeFromActiveRequests(instanceKey, requestConfig) {
      this.active_requests = this.active_requests.filter(request => (
        request.instanceKey !== instanceKey || request.requestConfig !== requestConfig
      ));
    }
  }



let axiosQ = new AxiosQueue()
store.$axiosQ = axiosQ;
app.config.globalProperties.$axiosQ = axiosQ;

app.use(store)
app.mount('#app')
