// *****************
// GRAPH STORE
// *****************
// This interface stores the details for the Network Graph Component
// 
import shared from '../components/shared.js'

export default {
    namespaced: true,
    state: {
        graph_data: {
            max_degrees: 1,
            nodes: [],
            relationships: [],
            active_layout: 'force',
        },
        active_topics: [],
    },
    actions: {       
        query_neo4j: async function({commit, getters}) {
            let uri = '/api/network_graph/'
            let data = {
              'search_module': this.state.draft_query.search_module,
              'collection': {
                'collection_type': 'authors',
                'records': getters.getGraphNodes
              }
            }
            // We also want to make sure that the Searchbox Record is sent if that's an author.
            const updated_graph_data = await this.$axiosQ.jumpQueue('gcapi', {
                method: 'post', 
                url: uri,
                data: data})
            return updated_graph_data
        },
        expand_graph: async function({commit, getters}, author_id) {
            let original_collection = {
              'collection_type': 'authors',
              'records': getters.getGraphNodes,
            }
            // Get a collection of coauthors of the input author.
            const similarity_response = await this.$axiosQ.jumpQueue('gcapi', {
              method: 'post', 
              url: '/api/similar/graph/',
              data: {
                'search_module': this.state.draft_query.search_module,
                'limit': 10,
                'collection': {
                  'collection_type': 'authors',
                  'records': [
                    {
                      'record_type': 'author',
                      'identifier': author_id
                    }
                  ]
                },
                'exclude_input': true,
            }})
            this.commit('APPEND_RECORDS', similarity_response.data.collection.records)
            // Get the new nodes and relationships.
            // filter original_collection
            const graph_response = await this.$axiosQ.jumpQueue('gcapi', {
                method: 'post', 
                url: '/api/network_graph/',
                data: {
                    'search_module': this.state.draft_query.search_module,
                    'collection': { 
                        'collection_type': 'authors',
                        'records': getters.getGraphNodes,
                    },
                    'exclude': original_collection
                }
            })
            return graph_response
        },
        update_graph_data: async function({state, getters, rootState, rootGetters, commit}, payload) {
            // reset graph data
            if (payload.method == 'reset') {
                commit('SET_GRAPH_DATA', {nodes: [], relationships: [], max_degrees:1})
            }
            const updated_graph_data = payload.graph_data
            
            // Handle all new Nodes 
            const new_node_ids = new Set([])
            var currently_drawn_nodes = _.map(state.graph_data['nodes'], 'id')
            const new_nodes = updated_graph_data.nodes.filter(node => {
                return !currently_drawn_nodes.includes("https://openalex.org/"+node.id)
            })

            let parsed_nodes = new_nodes.map(node => {
                node.id = "https://openalex.org/"+node.id
                new_node_ids.add(node.id)
                var display_name = rootGetters.getCurrentResultListByID[node.id].fields.display_name
                var searchbox_record = rootState.searchbox_collection.records[0]
                return adapt_nodes_for_echarts(node, display_name, searchbox_record??false)
            })

            // Handle all new relationships
            let parsed_relationships = updated_graph_data.relationships.map(function(edge){
                return relationship_to_echarts_edge(edge)
            })
            const existing_rels = _.map(state.graph_data.relationships, 'id')
            const new_rels = parsed_relationships.filter(relationship => {
                return !existing_rels.includes(relationship.source+'->'+relationship.target)
            })
            
            updated_graph_data['nodes'] = _.cloneDeep(state.graph_data['nodes']).concat(parsed_nodes)
            updated_graph_data['relationships'] = _.cloneDeep(state.graph_data['relationships']).concat(new_rels)

            //count occurences for node size based on degrees
            const all_rels = {}
            updated_graph_data['relationships'].map(function (rel) {
                if (!all_rels[rel['source']]) {all_rels[rel['source']] = 1}
                else {all_rels[rel['source']]+=1}
                if (!all_rels[rel['target']]) {all_rels[rel['target']] = 1}
                else {all_rels[rel['target']]+=1}
            });
            updated_graph_data['max_degrees'] = _.max(Object.values(all_rels))
            updated_graph_data['nodes'].map(function (node) {
                if (node.category == 'topic') return true
                node['symbolSize'] = 5 + 20 * (all_rels[node['id']] / updated_graph_data['max_degrees'])
                return node
            });

            // there will be some remaining singletons without relationships
            // they are not returned by Neo4J, so should be mapped separately:
            currently_drawn_nodes = _.map(updated_graph_data['nodes'], 'id')
            _.map(getters.getGraphNodes, function(author) {
                if (!currently_drawn_nodes.includes(author.identifier)) {
                    let node = adapt_nodes_for_echarts(
                        {id: author.identifier},
                        author.fields.display_name,
                        rootState.searchbox_collection.records[0]
                    )
                    node.symbolSize = 5
                    updated_graph_data['nodes'].push(node)
                    all_rels[author.identifier] = false
                }
            })
            commit('SET_GRAPH_DATA', updated_graph_data)
            return updated_graph_data
        },
        update_topic_layer: async function({state, getters, commit, rootGetters}, graph_element) {
            // make an existing copy of graph data
            let updated_graph_data = state.graph_data
            // clear existing topic_nodes
            updated_graph_data.nodes = updated_graph_data.nodes.filter(node => {return node.category !== 'topic'})
            // clear existing relationships with topics (which start with id T):
            updated_graph_data.relationships = updated_graph_data.relationships.filter(rel => {return rel.id.slice(0,1) !== 'T'}) 
            // then, we add every topic node in:
            this.getters.getActiveTopics.forEach(topic => {
                console.log('working on topic: ', topic)
                // we get the position of all related authors to center the new topic node
                const [x_coords, y_coords] = [[], []]
                rootGetters.getAuthorIDsByTopic[topic.id].forEach(author_id => {
                    const nodeIdx = getters.getGraphodeIndicesByKey[author_id]
                    let [x, y] = index_to_coordinate(graph_element, nodeIdx)
                    x_coords.push(x)
                    y_coords.push(y)
                    // and we add a relationship for every author to the topic.
                    updated_graph_data.relationships.push(make_topic_relationship(topic.id, author_id))
                })
                
                let topic_node_obj = {
                ...getters.getGraphOptions['series'][0]['categories'].find(category => {return category.name == 'topic'}),
                ...{
                    id: topic.id,
                    name: topic.display_name,
                    category: 'topic',
                    position: [ _.mean(x_coords), _.mean(y_coords)],
                  },
                }
                updated_graph_data.nodes.push(topic_node_obj);
            })
            console.log('updating topic layer with new data: ', updated_graph_data)
            commit('SET_GRAPH_DATA', updated_graph_data)
            return updated_graph_data
        },
        render_graph: async function({getters, state, commit}, {graph_element, silent = true, trigger='unknown'}) {
            if (silent) {
                commit('FREEZE_GRAPH', graph_element)
            }
            else {
                state.graph_data.active_layout = 'force'
            }   
            graph_element.setOption(getters.getGraphOptions)
            return true
        },
        highlight_topic: async function({state, getters, rootGetters, commit, dispatch}, topic_id) {
            let author_ids = []
            if (topic_id) {author_ids = rootGetters.getAuthorIDsByTopic?.[topic_id]}
            let updated_nodes = _.cloneDeep(state.graph_data.nodes)
            updated_nodes.forEach(node => {
                if (author_ids.includes(node.id) && node.category == 'author') {
                    node.itemStyle = {color:shared.colorMap()['--orange_highlight']};
                }
                else if (node.category == 'author') {
                    node.itemStyle = {color: shared.colorMap()['--secondary_color']}
                }
            })
            commit('UPDATE_NODES', updated_nodes)
            return true
        }
    },
    getters: {
        getGraphOptions(state) {return{
            animation: true,
            emphasis: {
                focus: 'adjacency',
                itemStyle: {
                    color: shared.colorMap()['--primary_color'],
                    borderWidth: '4',
                    borderColor: 'white',
                    borderType: 'solid',
                },
                label: {
                    backgroundColor:'white',
                    fontWeight: 400,
                    color: shared.colorMap()['--secondary_color'],
                    shadowColor: shared.colorMap()['--grey_divider'],
                }
            },
            tooltip: {
                show: true,
                enterable: true,
                trigger: 'item',
                triggerOn: 'none',
                className: 'popup-container',
                formatter: function(node) {
                    node.data.symbolSize = isNaN(node.data.symbolSize)?5:node.data.symbolSize
                    return `
                        <div class="hex-container">
                            <span id="expand_graph_button" title="Find collaborators of ${node.name}" class="hex-button top">
                                <i class="fa fa-plus"></i>
                            </span>
                        <div class="spacer">
                        <div class="circle" style="
                        height: ${node.data.symbolSize}px;
                        width:${node.data.symbolSize}px;"
                        >&nbsp;</div>
                        </div>
                            <span id="show_author_details_button" title="Show author details" class="hex-button bottom">
                                <i class="fa fa-address-card"></i>
                            </span>
                        </div>
                        <div class="display_name">${node.name}</div>`
                },
                position: 'right',
                background:'transparent',
                border:'none',
            },
            series: [
            {
                name: 'Connections',
                type: 'graph',
                layout: state.graph_data.active_layout,
                data: state.graph_data.nodes,
                links: state.graph_data.relationships,
                // zoom: 1,
                center: ["60%", "50%"],
                roam: true,
                nodeScaleRatio: 0,
                lineStyle: {
                    color: shared.colorMap()['--grey_text'],
                    opacity: .10,
                },
                itemStyle: {
                    color: shared.colorMap()['--secondary_color'],
                    borderWidth: '5',
                    borderColor: 'transparent',
                    borderType: 'solid',
                },
                label: {
                    show: true,
                    position: 'right',
                    formatter: '{b}'
                },
                emphasis: {
                    lineStyle: {
                        color: shared.colorMap()['--primary_color']
                    }
                },
                force: {
                    gravity: 0.3,
                    repulsion: 800,
                    edgeLength: [60, 200],
                    friction: 0.1,
                    layoutAnimation: true,
                    initLayout: 'circular',
                },
                labelLayout: {
                    hideOverlap: true
                },
                categories: [
                    {
                        name: "author",
                    },
                    {
                        name: "topic",
                        symbolSize: 120,
                        label: {
                        position: 'inside',
                        color: '#fff',
                        fontWeight: 'bolder', 
                        overflow: 'break',
                        width: 105,
                        textBorderColor: shared.colorMap()['--almost_black']+'60',
                        textBorderWidth: 2,
                        },
                        emphasis: {
                        label: {
                            backgroundColor: 'transparent',
                            color:'white',
                        }
                        },
                        tooltip: {
                        show: false
                        },
                        itemStyle: {
                            color: shared.colorMap()['--primary_color']+'60',
                        }
                    }
                ],
            }],
        }},
        getGraphNodes: function(state, getters, rootState, rootGetters) {
            let result_obj = rootGetters.getCurrentResultListByID
            if (!result_obj[rootState.searchbox_collection.records[0].identifier] && rootState.searchbox_collection.records[0].record_type == 'author') {
                result_obj[rootState.searchbox_collection.records[0].identifier] = rootState.searchbox_collection.records[0]
            }
            let result_list = Object.values(result_obj)
            result_list = result_list.filter(item => item.fields.id !== 'undefined')
            let minimized_results = _.map(result_list, result_record => {
                return {
                "identifier": result_record.identifier,
                "fields": {
                    display_name: result_record.fields.display_name,
                    id: result_record.fields.id,
                },
                "record_type": result_record.record_type,
                "source": result_record.source,
                }
            })
            return minimized_results
        },
        // return the graph nodes as an object {id: nodeIndex}
        getGraphodeIndicesByKey: function(state) {
            const graph_nodes_by_id = {}
            state.graph_data.nodes.forEach((node, nodeIndex) => {
                graph_nodes_by_id[node.id] = nodeIndex
            })
            return graph_nodes_by_id;
        },
    },
    mutations: { 
        SET_GRAPH_DATA: function(state, updated_graph_data) {
            state.graph_data = updated_graph_data
        },
        UPDATE_NODES: function(state, updated_nodes) {
            state.graph_data.nodes = updated_nodes
        },
        SET_ACTIVE_LAYOUT: function(state, new_layout) {
            state.graph_data.active_layout = new_layout
        },
        // Function to udpate the layout algorithm for the network graph\
        // primarily used to enable / disable force layout for highlights
        // or other setOption() mutations of the network graph.
        // Function to freeze the graph layout in the current state
        // by turning of force and locking the coordinates.
        FREEZE_GRAPH: function(state, network_graph) {
            if (state.graph_data.active_layout !== 'none') {
                state.graph_data.nodes.forEach((node, nodenum) => {
                    [node.x, node.y] = index_to_coordinate(network_graph, nodenum)
                })
                state.graph_data.active_layout = 'none'
            }
        }
    },
}

///////////////////////
// UTILITY Functions //
///////////////////////
// 
// @params Model (echarts.chart.getModel())
// @params nodeIndex (data index of the node)
// @params SeriesIndex (default 0)
// @returns [x, y]
function index_to_coordinate(network_graph, nodeIndex, seriesIndex = 0) {
    var model = network_graph.chart.getModel()
    console.log('model', model)
    const series = model.getSeriesByIndex(seriesIndex);
    console.log('series', series)
    const nodeData = series.getData();
    console.log('nodedata', nodeData)
    console.log('index_to_coord is returning ', nodeData.getItemLayout(nodeIndex))
    return nodeData.getItemLayout(nodeIndex) ?? [0,0]
}

// helper functions:
function adapt_nodes_for_echarts(node, display_name, searchbox_record=false) {
    node.name = display_name
    node.category = "author"
    // Add specific styling for the query author:
    if (searchbox_record?.record_type == 'author' && searchbox_record?.identifier == node.id){
        node.itemStyle =  {
            'color': shared.colorMap()['--primary_color'],
        }
        node.label = {
            show: true,
            fontWeight: 'bolder',
            color: shared.colorMap()['--primary_color']
        }
        node.symbolSize = 1.5*node.symbolSize
    }
    return node
}

// utility function to turn a Neo4J relationship into
// an edge in Echarts format.
function relationship_to_echarts_edge(edge) {
    let source_id = "https://openalex.org/"+edge['start_node']['id']
    let target_id = "https://openalex.org/"+edge['end_node']['id']
    return({
    'id': source_id+'->'+target_id,
    'source': source_id,
    'target': target_id,
    'tooltip': {
        'show': false,
    },
    'value': edge['properties']['count'],
    })
}

// utility function to create styled relationship for topic and author.
function make_topic_relationship(topic_id, author_id) {
    return {
        'id': topic_id+'->'+author_id,
        'source': topic_id,
        'target': author_id,
        'lineStyle': {
            'opacity': 0,
        },
        'tooltip': {
            'show': false,
        },
    }
}
